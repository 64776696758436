<template>
  <div class="digApp-box">
    <Header></Header>
    <new-home-header title="数字应用" @btnClick="btnClick" :showBtn="true" :useBtn="false" :free-btn="false"
                     :content="content"
                     imgStyle="height:360px" :imgSrc="`${require('@/assets/img/payImages/numberApp.png')}`"/>
    <bannerList :borderStyle="bodyStyle" title="无需技术开发，快速搭建应用" :dataList="dataList" :cardStyle="cardStyle"
                :cardTitleStyle="cardTitleStyle" :showImg="false" :cardContentStyle="cardContentStyle"/>
    <bannerList :borderStyle="bodyStyle2" title="专业服务团队 全方位服务保障" :dataList="dataList2" :cardStyle="cardStyle2"
                :imgStyle="imgStyle" :titleStyle="titleStyle"/>
    <div class="disposition-box">
      <div class="disposition-box-title">完整的功能配置</div>
      <div class="disposition-box-content">
        <div class="disposition-box-content-card" v-for="(item,i) in dispositionList" :key="i">
          <div class="title">{{ item.title }}</div>
          <div class="disposition-content" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <div class="customization">
      <div class="title">个性化定制方案</div>
      <div class="customization-content">
        <div class="card" v-for="(item,i) in customizationList" :key="i">
          <el-image class="imgBox" :src="item.image" fit="fill"></el-image>
          <div class="card-message">
            <span class="card-message-num">{{ i + 1 }}</span>
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'数字应用'"/>
    <Footer/>
  </div>
</template>

<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";

export default {
  name: "digitalApplicationsView",
  components: {bannerList, Header, Footer, applyTrial, newHomeHeader},
  data() {
    return {
      content: "基于组件化开发思想，平台打通应用软件从需求、涉及、开发、构建、测试、发布到部署的全流程，通过持续的调用与迭代，将丰富的项目积累和经验工具化、组件化、模板化，通过统一操作页面，真正做到让中小企业用户“开箱即用”。可以自由选用工具组件、业务应用、表单模板、智能化助手及解决方案；轻松实现流程、审批、报表、页面、数据处理等功能；按需生成Web应用、APP、大屏可视化应用、小程序等操作起来很方便，只需一键调用、简单“拖拽”和配置，就能在图形化的界面上，快速实现个性化业务功能定制，轻松完成应用搭建。",
      bodyStyle: {
        backgroundColor: "#F7F8FF"
      },
      cardStyle: {
        width: '290px',
        height: "210px",
        'box-shadow': '0px 3px 6px 0px rgba(226,228,243,0.64)',
        "align-items": "flex-start"
      },
      cardTitleStyle: {
        paddingLeft: '12px',
        marginTop: 0
      },
      cardContentStyle: {
        textAlign: "left"
      },
      dataList: [
        {title: '多平台开店', content: '集电脑PC、H5、APP、小程序、微应用于一体。'},
        {title: '统一数据', content: '共用空间，数据同步，五端合一，是中小企业建设数字化应用程序的优秀解决方案。'},
        {
          title: '独特优势',
          content: '系统具有操作简单、功能强大、稳定性好、易扩展、安全性强、维护方便、兼容性好等特点。'
        },
        {
          title: '价格实惠',
          content: '大大节省开发应用程序的费用，中小企业付一份钱，制作一次程序，就可在多端同时上线，实现多平台程序联合运营。'
        }
      ],
      dataList2: [
        {
          title: '免费规划',
          image: `${require('@/assets/img/home/L2_icon1.4d4e06fc.png')}`,
          content: '免费提供行业<br/>互联网推广解决方案'
        },
        {
          title: '免费维护',
          image: `${require('@/assets/img/home/L2_icon2.048eb66b.png')}`,
          content: '免费维护 免费培训<br/>免费上线 免费备案'
        },
        {
          title: '技术咨询',
          image: `${require('@/assets/img/home/L2_icon3.16593ffd.png')}`,
          content: '7*24小时一对一客服<br/>专业指导，答疑解惑'
        },
        {
          title: '功能持续更新',
          image: `${require('@/assets/img/home/L2_icon4.e2110832.png')}`,
          content: '专业团队运营，沉淀<br/>行业经验，持续更新功能'
        },
      ],
      bodyStyle2: {
        'background-image': `url(${require('@/assets/img/home/e6f36b17a350abb3dbc9cf363d292badbf5b665e244e21-r3uajw_87OJd5oClm.jpg')})`,
        'background-size': 'cover',
        'background-position': 'center',
      },
      cardStyle2: {
        width: "290px",
        height: "280px",
        backgroundColor: 'rgba(255,255,255,.89)'
      },
      imgStyle: {
        width: '65px',
        height: "72px"
      },
      titleStyle: {
        color: "#fff"
      },
      dispositionList: [
        {title: "内容系统", content: '产品系统   文章系统<br/>留言系统   图册系统<br/>素材系统   模板系统'},
        {title: "统计系统", content: '搜索统计   访客统计<br/>数据统计   来源统计<br/>产品统计   地区统计'},
        {title: "管理后台", content: '会员管理   产品管理<br/>文章管理   留言管理<br/>权限管理   样式编辑'},
        {title: "商城系统", content: '电脑商城   手机商城<br/>小程序商城   在线支付<br/>订单系统   社区团购'},
      ],
      customizationList: [
        {title: '需求确认', image: `${require('@/assets/img/home/L4_icon1.e7b4cc7a.png')}`},
        {title: '需求评估', image: `${require('@/assets/img/home/L4_icon2.5c92418a.png')}`},
        {title: '商务报价', image: `${require('@/assets/img/home/L4_icon3.e498db64.png')}`},
        {title: '签约合作', image: `${require('@/assets/img/home/L4_icon4.972b02a1.png')}`},
      ]
    }
  },
  methods: {
    btnClick() {
      this.$refs.dialog.show = true;
      // this.$router.push('/homeLogin')
    }
  }
}
</script>

<style lang="scss" scoped>
.disposition-box {
  padding: 60px 0;

  &-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
  }

  &-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    &-card {
      width: 290px;
      height: 230px;
      background-color: #F4F5FC;
      box-sizing: border-box;
      border: 1px solid #1935DE;

      .title {
        height: 85px;
        background-color: #1935DE;
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background-image: url('~@/assets/img/home/disposition.jpg');
        background-size: cover;

      }

      .disposition-content {
        text-align: center;
        line-height: 1.8;
        padding-top: 20px;
        font-size: 18px;
      }
    }
  }
}

.customization {
  background-image: url('~@/assets/img/home/7_0HsoQd_9K2.jpg');
  padding-bottom: 70px;
  background-size: cover;

  .title {
    text-align: center;
    font-size: 30px;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 60px;
    font-weight: bold;
  }

  &-content {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card {
      width: 280px;
      height: 180px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #BFC8FF;
        border-radius: 50%;
        right: -40px;
        z-index: 101;
        top: 34%;
        background-image: url('~@/assets/img/home/right.png');
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .imgBox {
        height: 70px;
      }

      &-message {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 24px;

        &-num {
          margin-right: 14px;
          background-color: #333;
          color: #fff;
          font-size: 12px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.digApp-box {
  min-width: 1360px;
}
</style>
<style scoped>
.digApp-box >>> .banner-card-container .left-btn {
  margin-top: 20px;
}

.digApp-box >>> .container .data-list .card {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
